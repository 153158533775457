import HomePage from './pages/HomePage';
import { Routes, Route } from "react-router-dom";
import './DashboardCss/Dashboard.css';
import Dashboard from './DashboardComponent/Dashboard';
import SwapPage from '../src/DashboardPage/SwapPage';
import PoolPage from '../src/DashboardPage/PoolPage';
import VeMercuryPage from '../src/DashboardPage/VeMercuryPage';
import FaucetPage from '../src/DashboardPage/FaucetPage';
import Sidebar from './DashboardComponent/Sidebar';
import Header from './DashboardComponent/Header';
import Launchpad from '../src/DashboardPage/Launchpad';
import SettingsPage from '../src/DashboardPage/SettingsPage';
import DashboardFooter from './DashboardComponent/DashboardFooter';

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';

const projectId = '9256340a0078da20d2a26bc6dc446b57'

const testnet = {
  chainId: 43113,
  name: 'Avalanche Fuji Testnet',
  currency: 'AVAX',
  explorerUrl: 'https:/https://testnet.snowtrace.io/',
  rpcUrl: 'https://avalanche-fuji-c-chain.publicnode.com'
}

const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com',
  icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ 
    metadata,
    defaultChainId: 43113,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: 'https://avalanche-fuji-c-chain.publicnode.com' // used for the Coinbase SDK
  }),
  chains: [testnet],
  projectId
})

function App() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  console.log("app.js",localStorage.getItem("walletAddress"))
  if(isConnected){
    localStorage.setItem("walletAddress",address);
  }else{
    localStorage.setItem("walletAddress","");
  }
  
  return (
    <div className='main_wrapper'>
     <Header />
     <Sidebar/>
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<Dashboard />} /> 
        <Route path="/swap" element={<SwapPage />} />
        <Route path="/pools" element={<PoolPage />} />
        <Route path="/ME" element={<VeMercuryPage />} />
        <Route path="/faucet" element={<FaucetPage />} /> 
        <Route path="/launchpad" element={<Launchpad />} /> 
        {/* <Route path="/settingspage" element={<SettingsPage />} /> */}
      </Routes>
     <DashboardFooter />
    </div>
  );
}

export default App;
